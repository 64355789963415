@import "../../node_modules/vuetify/src/styles/tools/functions";

$body-font-family: "LatoWeb", sans-serif;
$border-radius-root: 0.4rem;
$font-size-root: 1.6rem;

/* BUTTONS */
$btn-active-opacity: 1;
$btn-text-transform: none;
$btn-letter-spacing: 0;
$btn-font-sizes: (
  "x-small": 1rem,
  "small": 12px,
  "default": 14px,
  "large": 1.5rem,
  "x-large": 1.6rem,
);

/* PAGINATION */
//$pagination-border-radius: 0;
$pagination-item-height: 4.2rem;
$pagination-item-font-size: 1.4rem;
$pagination-item-padding: 1.2rem 1.6rem;

$color-pack: true;

$line-height-root: 1.5;

$rounded: ();
$rounded: map-deep-merge(
  (
    0: 0,
    "sm": 2px,
    null: $border-radius-root,
    "lg": $border-radius-root * 2,
    "xl": $border-radius-root * 6,
    "pill": 9999px,
    "circle": 50%,
  ),
  $rounded
);

$spacer: 0.4rem;

$spacers: ();
@if (type-of($spacers) == list) {
  @for $i from 0 through 16 {
    $spacers: map-merge(
      $spacers,
      (
        $i: $spacer * $i,
      )
    );
  }
}

$negative-spacers: ();
@if (type-of($negative-spacers) == list) {
  @for $i from 1 through 16 {
    $negative-spacers: map-merge($negative-spacers, ("n" + $i: -$spacer * $i));
  }
}

$grid-breakpoints: ();
$grid-breakpoints: map-deep-merge(
  (
    "xs": 0,
    "sm": 600px,
    "md": 960px,
    "lg": 1280px - 16px,
    "xl": 1920px - 16px,
  ),
  $grid-breakpoints
);

$grid-gutter: $spacer * 6;
$form-grid-gutter: $spacer * 2;
$grid-columns: 12;

$container-padding-x: $grid-gutter / 2;

$grid-gutters: ();
$grid-gutters: map-deep-merge(
  (
    "xs": $grid-gutter / 12,
    "sm": $grid-gutter / 6,
    "md": $grid-gutter / 3,
    "lg": $grid-gutter * 2/3,
    "xl": $grid-gutter,
  ),
  $grid-gutters
);

$container-max-widths: ();
$container-max-widths: map-deep-merge(
  (
    "md": map-get($grid-breakpoints, "md") * 0.9375,
    "lg": map-get($grid-breakpoints, "lg") * 0.9375,
    "xl": map-get($grid-breakpoints, "xl") * 0.9375,
  ),
  $container-max-widths
);

$display-breakpoints: ();
$display-breakpoints: map-deep-merge(
  (
    'print-only': 'only print',
    'screen-only': 'only screen',
    'xs-only': 'only screen and (max-width: #{map-get($grid-breakpoints, 'sm') - 1})',
    'sm-only': 'only screen and (min-width: #{map-get($grid-breakpoints, 'sm')}) and (max-width: #{map-get($grid-breakpoints, 'md') - 1})',
    'sm-and-down': 'only screen and (max-width: #{map-get($grid-breakpoints, 'md') - 1})',
    'sm-and-up': 'only screen and (min-width: #{map-get($grid-breakpoints, 'sm')})',
    'md-only': 'only screen and (min-width: #{map-get($grid-breakpoints, 'md')}) and (max-width: #{map-get($grid-breakpoints, 'lg') - 1})',
    'md-and-down': 'only screen and (max-width: #{map-get($grid-breakpoints, 'lg') - 1})',
    'md-and-up': 'only screen and (min-width: #{map-get($grid-breakpoints, 'md')})',
    'lg-only': 'only screen and (min-width: #{map-get($grid-breakpoints, 'lg')}) and (max-width: #{map-get($grid-breakpoints, 'xl') - 1})',
    'lg-and-down': 'only screen and (max-width: #{map-get($grid-breakpoints, 'xl') - 1})',
    'lg-and-up': 'only screen and (min-width: #{map-get($grid-breakpoints, 'lg')})',
    'xl-only': 'only screen and (min-width: #{map-get($grid-breakpoints, 'xl')})'
  ),
  $display-breakpoints
);

$font-weights: ();
$font-weights: map-deep-merge(
  (
    "thin": 100,
    "light": 300,
    "regular": 400,
    "medium": 500,
    "bold": 700,
    "black": 900,
  ),
  $font-weights
);

$heading-font-family: $body-font-family;

$headings: ();
$headings: map-deep-merge(
  (
    "h1": (
      "size": 6rem,
      "weight": 300,
      "line-height": 6rem,
      "letter-spacing": -0.015625em,
      "font-family": $heading-font-family,
      "text-transform": false,
    ),
    "h2": (
      "size": 3.75rem,
      "weight": 300,
      "line-height": 3.75rem,
      "letter-spacing": -0.0083333333em,
      "font-family": $heading-font-family,
      "text-transform": false,
    ),
    "h3": (
      "size": 3rem,
      "weight": 400,
      "line-height": 3.125rem,
      "letter-spacing": normal,
      "font-family": $heading-font-family,
      "text-transform": false,
    ),
    "h4": (
      "size": 2.125rem,
      "weight": 400,
      "line-height": 2.5rem,
      "letter-spacing": 0.0073529412em,
      "font-family": $heading-font-family,
      "text-transform": false,
    ),
    "h5": (
      "size": 1.5rem,
      "weight": 400,
      "line-height": 2rem,
      "letter-spacing": normal,
      "font-family": $heading-font-family,
      "text-transform": false,
    ),
    "h6": (
      "size": 1.25rem,
      "weight": 500,
      "line-height": 2rem,
      "letter-spacing": 0.0125em,
      "font-family": $heading-font-family,
      "text-transform": false,
    ),
    "subtitle-1": (
      "size": 1.6rem,
      "weight": normal,
      "line-height": 1.75rem,
      "letter-spacing": 0,
      "font-family": $body-font-family,
      "text-transform": false,
    ),
    "subtitle-2": (
      "size": 1.4rem,
      "weight": 500,
      "line-height": 1.375rem,
      "letter-spacing": 0,
      "font-family": $body-font-family,
      "text-transform": false,
    ),
    "body-1": (
      "size": 1.6rem,
      "weight": 400,
      "line-height": 1.5rem,
      "letter-spacing": 0,
      "font-family": $body-font-family,
      "text-transform": false,
    ),
    "body-2": (
      "size": 1.4rem,
      "weight": 400,
      "line-height": 1.25rem,
      "letter-spacing": 0,
      "font-family": $body-font-family,
      "text-transform": false,
    ),
    "button": (
      "size": 0.875rem,
      "weight": 500,
      "line-height": 2.25rem,
      "letter-spacing": 0,
      "font-family": $body-font-family,
      "text-transform": uppercase,
    ),
    "caption": (
      "size": 0.75rem,
      "weight": 400,
      "line-height": 1.25rem,
      "letter-spacing": 0,
      "font-family": $body-font-family,
      "text-transform": false,
    ),
    "overline": (
      "size": 0.75rem,
      "weight": 500,
      "line-height": 2rem,
      "letter-spacing": 0.1666666667em,
      "font-family": $body-font-family,
      "text-transform": uppercase,
    ),
  ),
  $headings
);

$typography: ();
@each $type, $values in $headings {
  $typography: map-deep-merge($typography, (#{$type}: map-values($values)));
}

$transition: (
  "fast-out-slow-in": cubic-bezier(0.4, 0, 0.2, 1),
  "linear-out-slow-in": cubic-bezier(0, 0, 0.2, 1),
  "fast-out-linear-in": cubic-bezier(0.4, 0, 1, 1),
  "ease-in-out": cubic-bezier(0.4, 0, 0.6, 1),
  "fast-in-fast-out": cubic-bezier(0.25, 0.8, 0.25, 1),
  "swing": cubic-bezier(0.25, 0.8, 0.5, 1),
);
$primary-transition: 0.3s map-get($transition, "swing");
$secondary-transition: 0.2s map-get($transition, "ease-in-out");

// Ripples //;
$ripple-animation-transition-in: transform 0.25s
    map-get($transition, "fast-out-slow-in"),
  opacity 0.1s map-get($transition, "fast-out-slow-in");
$ripple-animation-transition-out: opacity 0.3s
  map-get($transition, "fast-out-slow-in");
$ripple-animation-visible-opacity: 0.15;

// Elements //;
$bootable-transition: 0.2s map-get($transition, "fast-out-slow-in");
$blockquote-font-size: 18px;
$blockquote-font-weight: 300;
$code-background-color: #fbe5e1;
$code-color: #c0341d;
$code-kbd-border-radius: 3px;
$code-kbd-font-size: 85%;
$code-kbd-font-weight: 900;
$code-padding: 0 0.4rem;
$kbd-color: #ffffff;
$kbd-padding: 0.2rem 0.4rem;
$kbd-background-color: #212529;
$input-top-spacing: 16px;
$text-field-active-label-height: 12px;



//btn group
$btn-toggle-btn-opacity: 1;
$btn-toggle-btn-height: 36px;

/*TEXT-FIELD*/
$text-field-filled-full-width-outlined-single-line-slot-min-height: 32px;
$text-field-filled-full-width-outlined-dense-slot-min-height: 32px;
$text-field-dense-icon-append-prepend-margin-top: 5px;

//list items
$list-item-dense-title-font-size: 12px;

//datepicker
$date-picker-table-active-date-color: #ee6363;