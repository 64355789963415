@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Black.woff2') format('woff2'),
         url('Lato-Black.woff') format('woff');
    font-style: normal;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-BlackItalic.woff2') format('woff2'),
         url('Lato-BlackItalic.woff') format('woff');
    font-style: italic;
    font-weight: 900;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Heavy.woff2') format('woff2'),
    url('Lato-Heavy.woff') format('woff');
    font-style: normal;
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-HeavyItalic.woff2') format('woff2'),
    url('Lato-HeavyItalic.woff') format('woff');
    font-style: italic;
    font-weight: 800;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Bold.woff2') format('woff2'),
         url('Lato-Bold.woff') format('woff');
    font-style: normal;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-BoldItalic.woff2') format('woff2'),
         url('Lato-BoldItalic.woff') format('woff');
    font-style: italic;
    font-weight: 700;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Semibold.woff2') format('woff2'),
    url('Lato-Semibold.woff') format('woff');
    font-style: normal;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-SemiboldItalic.woff2') format('woff2'),
    url('Lato-SemiboldItalic.woff') format('woff');
    font-style: italic;
    font-weight: 600;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Medium.woff2') format('woff2'),
    url('Lato-Medium.woff') format('woff');
    font-style: normal;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-MediumItalic.woff2') format('woff2'),
    url('Lato-MediumItalic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Regular.woff2') format('woff2'),
    url('Lato-Regular.woff') format('woff');
    font-style: normal;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Italic.woff2') format('woff2'),
         url('Lato-Italic.woff') format('woff');
    font-style: italic;
    font-weight: 400;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Light.woff2') format('woff2'),
         url('Lato-Light.woff') format('woff');
    font-style: normal;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-LightItalic.woff2') format('woff2'),
         url('Lato-LightItalic.woff') format('woff');
    font-style: italic;
    font-weight: 300;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Thin.woff2') format('woff2'),
         url('Lato-Thin.woff') format('woff');
    font-style: normal;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-ThinItalic.woff2') format('woff2'),
         url('Lato-ThinItalic.woff') format('woff');
    font-style: italic;
    font-weight: 200;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-Hairline.woff2') format('woff2'),
    url('Lato-Hairline.woff') format('woff');
    font-style: normal;
    font-weight: 100;
    font-display: swap;
}

@font-face {
    font-family: 'LatoWeb';
    src: url('Lato-HairlineItalic.woff2') format('woff2'),
    url('Lato-HairlineItalic.woff') format('woff');
    font-style: italic;
    font-weight: 100;
    font-display: swap;
}

